<template>
  <b-card-code title="تحويل الحالة ">
      <validation-observer ref="simple">
    <b-row>
      <b-col md="6" xl="3">
        <validation-provider #default="{ errors }" name="service" rules="required">
          <b-form-group label-for="service" :state="errors.length > 0 ? false : null">
            <label> الخدمة</label>
            <v-select
              v-model="mainService"
              :reduce="(val) => val.value"
              :options="optionService"
            />
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <b-col md="6" xl="3">
        <validation-provider #default="{ errors }" name="type" rules="required">
          <b-form-group label-for="type" :state="errors.length > 0 ? false : null">
            <label> نوع التحويل</label>
            <v-select
              v-model="type"
              :reduce="(val) => val.value"
              :options="optionType"
            />
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <b-col md="12" xl="4">
        <validation-provider #default="{ errors }" name="note" rules="required">
          <b-form-group label-for="note" :state="errors.length > 0 ? false : null">
            <label>الملاحظات</label>
            <b-form-textarea
              id="textarea-default"
              v-model="Notes"
              placeholder="الملاحظات"
              rows="2"
            />
          </b-form-group>
          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </b-col>
    
    </b-row>
    <b-row>


      <b-col>
        <b-form-group>
                <b-form-checkbox
                v-model="sharePersonInfo"

                >
                مشاركة المعلومات الشخصية
                </b-form-checkbox>
              </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
                <b-form-checkbox
                 v-model="shareDocuments"

                >
                مشاركة وثائق
                
                </b-form-checkbox>
              </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
                <b-form-checkbox
                v-model="shareContactDetails"

                >
                مشاركة معلومات الاتصال
                </b-form-checkbox>
              </b-form-group>
      </b-col>
    
      <b-col>
        <b-form-group>
                <b-form-checkbox
                v-model="shareService"

                >
                مشاركة الخدمات
                </b-form-checkbox>
              </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
                <b-form-checkbox
                v-model="shareWeaknesses"

                >
                مشاركة نقاط الضعف
                </b-form-checkbox>
              </b-form-group>
      </b-col>
    </b-row>
    </validation-observer>
    <b-row>
      <b-col class="border-Primary" xl="2">
        <b-button variant="purple" @click="ContinueStatus" :disabled="transferDone">
          <span class="align-middle"> تحويل</span>
        </b-button>
      </b-col>
      <b-col xl="4"></b-col>
    </b-row>
  </b-card-code>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import "flatpickr/dist/flatpickr.css";
import BCardCode from "@core/components/b-card-code";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BFormRating,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BButton,
  BFormInvalidFeedback,
  BFormCheckbox
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    BCardCode,
    vSelect,
    BFormInvalidFeedback,
    BFormRating,
    BCardText,
    flatPickr,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    ToastificationContent,
    BButton,
    ValidationProvider,
    ValidationObserver,
    required,
    BFormCheckbox
  },

  data() {
    return {
      optionService: [],
      mainService: "",
      sharePersonInfo:false,
      shareDocuments:false,
      shareContactDetails:false,
      shareService:false,
      shareWeaknesses:false,
      Notes: "",
      type:'',
      optionType:[{
        label:'تحويل',
        value:'assign'
      },
    {
        label:' تحويل أخر',
        value:'other_assign'
    }],
      transferDone:false
    };
  },
  created() {
    this.getServiceType();
  },
  methods: {
    getServiceType() {
      this.$http.get("/api/v1/beneficiaries_page/get_dropdown_data").then((res) => {
        let proposal_services = res.data.data.main_services;
             const serviceId = localStorage.getItem("roleService");
        //console.log(proposal_services)
        proposal_services.forEach((el) => {
          
          this.optionService.push({ label: el.name, value: el.id });
          
        });
      });
    },
    ContinueStatus() {

      return new Promise((resolve, reject) => {
        this.$refs.simple
          .validate()
          .then((success) => {
            if (success) {
              let data_sharing_options={
        personal_info:this.sharePersonInfo,
documents:this.shareDocuments,
contact_details:this.shareContactDetails,
services:this.shareService,
weaknesses:this.shareWeaknesses
              }
      let data = {
        data_sharing_options,

    to_main_service_id:this.mainService,
    offered_service_id:parseInt(this.$route.params.id),
    notes:this.Notes,
    type:this.type

      };
      //console.log(data);
      let url = `api/v1/service_referrals`;

      //console.log(url);
      this.$http.post(url, data).then((res) => {
        //console.log(res);
        this.mainService=''
        this.Notes=''
        this.type=''
        this.sharePersonInfo=false
        this.shareDocuments=false
        this.shareContactDetails=false
        this.shareService=false
        this.shareWeaknesses=false
        requestAnimationFrame(() => {
                  this.$refs.simple.reset();
                });
        // this.transferDone=true
         this.$swal({
              title: "تم التحويل للحالة بنجاح",
        icon: "success",
        confirmButtonText: "موافق",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      this.$router.push({ name: 'service-list' })

      }).catch((error) => {
            //console.log(error.response.data.message);
            this.$swal({
              title:`${error.response.data.message}`,

              icon: "info",
              confirmButtonText: "موافق",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });

   }})}) },

  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.btn-purple {
  color: white;
  background-color: #0971b8 !important;
}
</style>
